import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeOpenedModal } from '../../../../redux/actions/headerModal';
import { useTranslation } from 'react-i18next';
import { ModalHeader, StyledModal, StyledModalBody } from '../../../../components/Modals';
import {
  SettingsItem,
  SettingsItemContent,
  SettingsItemSubTitle,
  SettingsItemTitle
} from '../../../settings/SettingsItem';
import { Palette } from '../../../../components/utils';
import { FaHouse } from 'react-icons/fa6';
import { FaHashtag } from 'react-icons/fa';
import { IoKeypad } from 'react-icons/io5';
import { AVAILABLE_TABS } from '..';

const UnitLockDetails = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { modal, data } = useSelector((state) => state.headerModal);
  const { doorPin, unitInfo } = useSelector((state) => state.unit);
  const { orgConfig } = useSelector((state) => state.Organization);

  const onClose = () => {
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
  };
  return (
    <StyledModal show={modal === 'unitLockDetails'} onClose={onClose} closeOnBackdrop={true}>
      <ModalHeader title={t('Lock info')} />
      <StyledModalBody>
        <SettingsItem>
          <FaHouse size={24} color={Palette.HAZY_BLUE} />
          <SettingsItemContent>
            <SettingsItemTitle>
              {orgConfig?.config?.suite_enabled && data?.from === AVAILABLE_TABS.UNITS
                ? t('Room number')
                : t('Unit number')}
            </SettingsItemTitle>
            <SettingsItemSubTitle>{data?.suiteName || unitInfo.data.unit_num}</SettingsItemSubTitle>
          </SettingsItemContent>
        </SettingsItem>

        <SettingsItem>
          <FaHashtag size={24} color={Palette.HAZY_BLUE} />
          <SettingsItemContent>
            <SettingsItemTitle>{t('Lock id')}</SettingsItemTitle>
            <SettingsItemSubTitle>{unitInfo.data.serial}</SettingsItemSubTitle>
          </SettingsItemContent>
        </SettingsItem>

        {doorPin.map((obj, index) => {
          if (parseInt(obj.TypeID) === 1) {
            return (
              <SettingsItem>
                <IoKeypad size={24} color={Palette.HAZY_BLUE} />
                <SettingsItemContent>
                  <SettingsItemTitle>
                    {t('Master PIN')} {obj.Slot + 1}
                  </SettingsItemTitle>
                  <SettingsItemSubTitle>{obj.Code}</SettingsItemSubTitle>
                </SettingsItemContent>
              </SettingsItem>
            );
          } else if (parseInt(obj.TypeID) === 2) {
            return (
              <SettingsItem>
                <IoKeypad size={24} color={Palette.HAZY_BLUE} />
                <SettingsItemContent>
                  <SettingsItemTitle>
                    {t('User PIN')} {obj.Slot + 1}
                  </SettingsItemTitle>
                  <SettingsItemSubTitle>{obj.Code}</SettingsItemSubTitle>
                </SettingsItemContent>
              </SettingsItem>
            );
          } else {
            return (
              <SettingsItem>
                <IoKeypad size={24} color={Palette.HAZY_BLUE} />
                <SettingsItemContent>
                  <SettingsItemTitle>
                    {t('Guest PIN')} {obj.Slot + 1}
                  </SettingsItemTitle>
                  <SettingsItemSubTitle>{obj.Code}</SettingsItemSubTitle>
                </SettingsItemContent>
              </SettingsItem>
            );
          }
        })}
      </StyledModalBody>
    </StyledModal>
  );
};

export default UnitLockDetails;
