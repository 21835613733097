import React from 'react';
import { CDataTable } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Fonts, Palette } from './utils';

const Table = ({
  items,
  loading,
  fields,
  itemsPerPage,
  onRowClick,
  scopedSlots,
  pagination,
  rowHeight,
  useNewStyle = false,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <TableStyle rowHeight={rowHeight} useNewStyle={useNewStyle}>
      <CDataTable
        noItemsViewSlot={loading ? ' ' : undefined}
        noItemsView={{
          noResults: t('No filtering results available'),
          noItems: t('No items available')
        }}
        items={items}
        loading={loading}
        fields={fields}
        itemsPerPage={Number(itemsPerPage)}
        hover
        pagination={pagination}
        responsive
        onRowClick={onRowClick}
        scopedSlots={scopedSlots}
        {...rest}
      />
    </TableStyle>
  );
};

const sharedTableStyles = css`
  tr {
    border-bottom: ${Palette.STROKE_GREY} solid 0.5px;

    &:hover {
      background-color: rgba(117, 117, 117, 0.2);
      cursor: pointer;
    }
  }

  td {
    height: 43px;
    align-items: center;
    color: ${Palette.BLACK};
    font-family: ${Fonts.OPEN_SANS_FONT};
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border-top: none !important;
  }
`;

const newTableStyles = css`
  ${sharedTableStyles}

  tr {
    max-height: ${({ rowHeight }) => rowHeight || '40px'} !important;
  }

  td {
    font-size: 12px;
    max-height: ${({ rowHeight }) => rowHeight || '40px'} !important;
    padding: 0 !important;
  }
`;

const oldTableStyles = css`
  ${sharedTableStyles}

  td {
    font-size: 14px;
    height: ${({ rowHeight }) => rowHeight || '56px'} !important;
  }
`;

const TableStyle = styled.div`
  flex-grow: 1;
  overflow: auto;

  h2 {
    font-family: ${Fonts.OPEN_SANS_FONT};
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    svg {
      display: none;
    }
  }

  table {
    width: 100%;
  }

  thead {
    width: 100%;
    border-bottom: 0.5px solid ${Palette.STROKE_GREY};

    .table-sm {
      &:hover {
        background-color: initial !important;
        cursor: auto;
      }
    }

    tr:first-child {
      border-bottom: none;
      padding: 0;

      th {
        height: 12px;
        padding: 24px 0;
        padding-right: 12px;
        align-content: center;
        color: ${Palette.BLACK_50};
        font-family: ${Fonts.OPEN_SANS_FONT};
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        border-bottom: none !important;
        border-top: none !important;
        background-color: ${Palette.WHITE};

        &:after {
          line-height: 60px;
        }

        &:hover {
          cursor: auto;
        }

        svg {
          color: ${Palette.BLACK};
          width: 18px;
          height: 18px;
          align-self: center;
          right: auto;
          top: 45%;
        }
      }
    }
  }

  ${({ useNewStyle }) => (useNewStyle ? newTableStyles : oldTableStyles)}
`;

export default Table;
