import React, { useState } from 'react';
import { CCol, CRow } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Palette } from '../../../components/utils';
import { MdOutlineEmail } from 'react-icons/md';
import {
  SettingsModalBoldText,
  SettingsModalBorder,
  SettingsModalSubHeader
} from '../../settings/SettingsModals';
import { ModalCloseButton } from '../../settings/SettingsButton';
import { FaLock, FaUser, FaUserEdit } from 'react-icons/fa';
import {
  SettingsItem,
  SettingsItemContent,
  SettingsItemSubTitle,
  SettingsItemTitle
} from '../../settings/SettingsItem';
import { BsPostcard } from 'react-icons/bs';
import { SiPluscodes } from 'react-icons/si';
import { closeActiveModal, openModal } from '../../../helper/modals';
import {
  addOrganization,
  getAllOrganizations,
  getOrganizations,
  setAllOrgStatus,
  uploadOrgLogo
} from '../../../redux/actions/organization';
import { GET_ALL_ORGANIZATIONS } from '../../../redux/constants';
import { requestPending } from '../../../utils/status';
import { ToggleHelper } from '../ManageUserComponents';
import { setToast } from '../../../redux/actions/window';
import { defaultImgCDN } from '../../../helper';
import {
  ModalButtons,
  ModalHeader,
  StyledModal,
  StyledModalBody
} from '../../../components/Modals';
import { ImageContainer, StyledImage } from '../../settings/SettingsImages';
import { resetOrganizationData } from '../../../redux/actions/addOrganization';
import { ORGANIZATION_MODES, ORGANIZATION_PROVIDERS } from '../../../utils/mappings';
import { getLockProviderNameByID } from '../../../utils/utils';
import { searchTable, setSearchPagination, setSearchState } from '../../../redux/actions/search';
import { setUserSearch } from '../../../redux/actions/user';
import { SEARCH_TABLES } from '../../../helper/constants';

const format = (arr, valueId) => {
  return arr.find((value) => value.id === valueId)?.value || 'Unknown';
};

const AddOrganizationModalStep4 = () => {
  const { t } = useTranslation();
  const { modal } = useSelector((state) => state.headerModal);
  const addOrganizations = useSelector((state) => state.addOrganization);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const searchState = useSelector((state) => state.search);
  const dispatch = useDispatch();

  const formatProvider = (valueId) => {
    return format(ORGANIZATION_PROVIDERS, valueId);
  };

  const formatMode = (valueId) => {
    return format(ORGANIZATION_MODES, valueId);
  };

  const onSubmit = () => {
    setIsSubmitting(true);
    if (addOrganizations.avatar.blobData) {
      const imgData = new FormData();
      imgData.append('image', addOrganizations.avatar.blobData, addOrganizations.avatar.blobName);
      dispatch(
        uploadOrgLogo({
          body: imgData,
          success: (res) => {
            submitAddOrganization(addOrganizations, res.data.filename);
          },
          fails: () => {
            dispatch(
              setToast({
                toastShow: true,
                toastMessage: t('Organization Avatar update failed!'),
                modal: 'errorToast'
              })
            );
          }
        })
      );
    } else {
      submitAddOrganization(addOrganizations);
    }
  };

  const submitAddOrganization = (values, filename) => {
    const formattedValues = {
      name: values.name,
      contact: values.contact,
      email: values.email,
      location: values.dataCenter,

      prov_tenant: values.residentProvider,
      prov_tenant_mode: values.residentMode,
      prov_property: values.propertyProvider,
      prov_property_mode: values.propertyMode,
      prov_service: values.serviceProvider,
      prov_service_mode: values.serviceMode,
      logo: filename || '',
      provider_access_token: values.providerAccessToken,
      provider_refresh_token: values.providerRefreshToken,

      config: {
        service_request_url: values.serviceRequestURL,
        user_pin_len: 6,
        lock_provider: values.options.lockProvider,

        suite_enabled: values.options.enableMultiLock,
        enable_posts: values.options.enablePost,
        enable_offers: values.options.enableOffers,
        enable_booking: values.options.enableBooking,
        enable_lock_screen: values.options.enableLockScreen,
        enable_service_requests: values.options.enableServiceRequest,
        enable_security: values.options.enableSecurity,
        show_user_pin_to_admin: values.options.showUserPinToAdmin,
        readonly_users: values.options.enableReadOnlyUsers,
        show_user_pin_resident: values.options.enableShowUserPinResident,
        show_user_pin_admin: values.options.enableShowSuperPinAdmin,
        org_allow_edits: values.options.enableEdit
      }
    };

    dispatch(
      addOrganization({
        body: formattedValues,
        success: (res) => {
          dispatch(getOrganizations({ location: formattedValues.location }));
          dispatch(setAllOrgStatus({ status: requestPending(GET_ALL_ORGANIZATIONS) }));
          dispatch(getAllOrganizations({ location: formattedValues.location }));
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Organization has been successfully created!')
            })
          );
          dispatch(resetOrganizationData());
          dispatch(
            searchTable({
              entity: SEARCH_TABLES.ORGANIZATIONS,
              orgID: 0,
              body: {
                search: searchState.search,
                minCreationDate: searchState.minCreationDate,
                maxCreationDate: searchState.maxCreationDate,
                filters: searchState.filters,
                ordering: searchState.ordering,
                pagination: searchState.pagination
              },
              success: (res) => {
                dispatch(
                  setSearchState({
                    currentData: res.data.data ?? [],
                    currentPaginationData: res.data.pagination
                  })
                );

                if (!res.data.data) {
                  dispatch(setUserSearch({ page: 1 }));
                  dispatch(
                    setSearchPagination({
                      page: 1,
                      limit: 20
                    })
                  );
                }
              }
            })
          );
          closeActiveModal();
        },
        fail: (res) => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Organization Creating Failed!'),
              modal: 'errorToast'
            })
          );
        },
        finally: () => {
          setIsSubmitting(false);
        }
      })
    );
  };

  return (
    <StyledModal
      show={modal === 'AddOrganizationModalStep4'}
      closeOnBackdrop={false}
      onClose={closeActiveModal}>
      <ModalHeader title={t('Add Organization')} />

      <StyledModalBody>
        <SettingsModalSubHeader>
          <FaUserEdit size={24} color={Palette.HAZY_BLUE} />
          <SettingsModalBoldText>{t('User Image')}</SettingsModalBoldText>
        </SettingsModalSubHeader>

        <SettingsModalBorder height={245}>
          <ImageContainer>
            <StyledImage
              src={
                addOrganizations.avatar.blobURL ? addOrganizations.avatar.blobURL : defaultImgCDN
              }
            />
          </ImageContainer>
          <SettingsModalBoldText>{addOrganizations.name}</SettingsModalBoldText>
        </SettingsModalBorder>

        <SettingsItem>
          <FaUser size={24} color={Palette.HAZY_BLUE} />
          <SettingsItemContent>
            <SettingsItemTitle>{t('Organization Name')}</SettingsItemTitle>
            <SettingsItemSubTitle>{addOrganizations.name}</SettingsItemSubTitle>
          </SettingsItemContent>
        </SettingsItem>

        <SettingsItem>
          <MdOutlineEmail size={24} color={Palette.HAZY_BLUE} />
          <SettingsItemContent>
            <SettingsItemTitle>{t('Email')}</SettingsItemTitle>
            <SettingsItemSubTitle>{addOrganizations.email}</SettingsItemSubTitle>
          </SettingsItemContent>
        </SettingsItem>

        <SettingsItem>
          <BsPostcard size={24} color={Palette.HAZY_BLUE} />
          <SettingsItemContent>
            <SettingsItemTitle>{t('Contact')}</SettingsItemTitle>
            <SettingsItemSubTitle>{addOrganizations.contact}</SettingsItemSubTitle>
          </SettingsItemContent>
        </SettingsItem>

        <SettingsItem>
          <BsPostcard size={24} color={Palette.HAZY_BLUE} />
          <SettingsItemContent>
            <SettingsItemTitle>{t('Select Data Center')}</SettingsItemTitle>
            <SettingsItemSubTitle>{addOrganizations.dataCenter}</SettingsItemSubTitle>
          </SettingsItemContent>
        </SettingsItem>

        <SettingsItem>
          <FaLock size={24} color={Palette.HAZY_BLUE} />
          <SettingsItemContent>
            <SettingsItemTitle>{t('Lock Provider')}</SettingsItemTitle>
            <SettingsItemSubTitle>
              {getLockProviderNameByID(addOrganizations.options.lockProvider)}
            </SettingsItemSubTitle>
          </SettingsItemContent>
        </SettingsItem>

        <CRow>
          <CCol>
            <SettingsItem>
              <FaUser size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{t('Resident Provider')}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {formatProvider(addOrganizations.residentProvider)}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>
          </CCol>
          <CCol>
            <SettingsItem>
              <FaUser size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{t('Resident Mode')}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {formatMode(addOrganizations.residentMode)}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>
          </CCol>
        </CRow>

        <CRow>
          <CCol>
            <SettingsItem>
              <BsPostcard size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{t('Property Provider')}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {formatProvider(addOrganizations.propertyProvider)}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>
          </CCol>
          <CCol>
            <SettingsItem>
              <BsPostcard size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{t('Property Mode')}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {formatMode(addOrganizations.propertyMode)}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>
          </CCol>
        </CRow>

        <CRow>
          <CCol>
            <SettingsItem>
              <BsPostcard size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{t('Service Provider')}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {formatProvider(addOrganizations.serviceProvider)}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>
          </CCol>
          <CCol>
            <SettingsItem>
              <BsPostcard size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{t('Service Mode')}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {formatMode(addOrganizations.serviceMode)}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>
          </CCol>
        </CRow>

        <SettingsItem>
          <SiPluscodes size={24} color={Palette.HAZY_BLUE} />
          <SettingsItemContent>
            <SettingsItemTitle>{t('Pin Length')}</SettingsItemTitle>
            <SettingsItemSubTitle>{addOrganizations.userPinLength}</SettingsItemSubTitle>
          </SettingsItemContent>
        </SettingsItem>

        <SettingsItem>
          <MdOutlineEmail size={24} color={Palette.HAZY_BLUE} />
          <SettingsItemContent>
            <SettingsItemTitle>{t('Service URL')}</SettingsItemTitle>
            <SettingsItemSubTitle>
              {addOrganizations.serviceRequestURL || 'Not Set'}
            </SettingsItemSubTitle>
          </SettingsItemContent>
        </SettingsItem>

        <SettingsItem>
          <BsPostcard size={24} color={Palette.HAZY_BLUE} />
          <SettingsItemContent>
            <SettingsItemTitle>{t('Configuration')}</SettingsItemTitle>
            <SettingsItemSubTitle
              cssOverride={`
                display: flex; 
                flex-direction: column; 
                gap: 18px; 
                padding-top: 15px;
              `}>
              <CRow>
                <CCol>
                  <SettingsItem>
                    <SettingsItemTitle>{t('Enable Post')}</SettingsItemTitle>
                    <ToggleHelper enabled={addOrganizations.options.enablePost} />
                  </SettingsItem>
                </CCol>
                <CCol>
                  <SettingsItem>
                    <SettingsItemTitle>{t('Enable Offers')}</SettingsItemTitle>
                    <ToggleHelper enabled={addOrganizations.options.enableOffers} />
                  </SettingsItem>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <SettingsItem>
                    <SettingsItemTitle>{t('Enable Booking')}</SettingsItemTitle>
                    <ToggleHelper enabled={addOrganizations.options.enableBooking} />
                  </SettingsItem>
                </CCol>
                <CCol>
                  <SettingsItem>
                    <SettingsItemTitle>{t('Enable Lock Screen')}</SettingsItemTitle>
                    <ToggleHelper enabled={addOrganizations.options.enableLockScreen} />
                  </SettingsItem>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <SettingsItem>
                    <SettingsItemTitle>{t('Enable Service Requests')}</SettingsItemTitle>
                    <ToggleHelper enabled={addOrganizations.options.enableServiceRequest} />
                  </SettingsItem>
                </CCol>
                <CCol>
                  <SettingsItem>
                    <SettingsItemTitle>{t('Read Only Users')}</SettingsItemTitle>
                    <ToggleHelper enabled={addOrganizations.options.enableReadOnlyUsers} />
                  </SettingsItem>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <SettingsItem>
                    <SettingsItemTitle>{t('Enable Security')}</SettingsItemTitle>
                    <ToggleHelper enabled={addOrganizations.options.enableSecurity} />
                  </SettingsItem>
                </CCol>
                <CCol>
                  <SettingsItem>
                    <SettingsItemTitle>{t('Show User Pin To Admin')}</SettingsItemTitle>
                    <ToggleHelper enabled={addOrganizations.options.showUserPinToAdmin} />
                  </SettingsItem>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <SettingsItem>
                    <SettingsItemContent>
                      <SettingsItemTitle>{t('Show Super PIN (Admin)')}</SettingsItemTitle>
                    </SettingsItemContent>
                    <ToggleHelper enabled={addOrganizations.options.enableShowSuperPinAdmin} />
                  </SettingsItem>
                </CCol>
                <CCol>
                  <SettingsItem>
                    <SettingsItemContent>
                      <SettingsItemTitle>{t('Allow Edit')}</SettingsItemTitle>
                    </SettingsItemContent>
                    <ToggleHelper enabled={addOrganizations.options.enableEdit} />
                  </SettingsItem>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <SettingsItem>
                    <SettingsItemContent>
                      <SettingsItemTitle>{t('Show User Pin (Resident)')}</SettingsItemTitle>
                    </SettingsItemContent>
                    <ToggleHelper enabled={addOrganizations.options.enableShowUserPinResident} />
                  </SettingsItem>
                </CCol>
                <CCol>
                  <SettingsItem>
                    <SettingsItemContent>
                      <SettingsItemTitle>{t('Enable Multi Lock')}</SettingsItemTitle>
                    </SettingsItemContent>
                    <ToggleHelper enabled={addOrganizations.options.enableMultiLock} />
                  </SettingsItem>
                </CCol>
              </CRow>
            </SettingsItemSubTitle>
          </SettingsItemContent>
        </SettingsItem>
      </StyledModalBody>
      <ModalButtons
        leftButtonText={t('Back')}
        leftButtonClick={() => openModal('AddOrganizationModalStep3')}
        rightButtonText={t('Add Organization')}
        rightButtonClick={onSubmit}
        disabled={isSubmitting}
      />
    </StyledModal>
  );
};

export default AddOrganizationModalStep4;