import React, { useEffect, useState } from 'react';
import { setToast } from '../../redux/actions/window';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import UserAsyncDropDown from '../../components/UserAsyncDropDown';
import { getAllBuildings } from '../../redux/actions/building';
import { changeOpenedModal } from '../../redux/actions/headerModal';
import { clearServiceError, createService, getServices } from '../../redux/actions/services';
import {
  getUser,
  getUserDeviceSlots,
  setUserDeviceSlots,
  setUserSearch
} from '../../redux/actions/user';
import { CONTACT_WITH_MAP, PRIORITY_MAPPING } from '../../utils/mappings';
import { useTranslation } from 'react-i18next';
import { categoryTypes } from './categories';
import { DATE_FORMATS, UserRole } from '../users/constant';
import localization from 'moment/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';
import localeEn from 'antd/es/date-picker/locale/en_US';
import i18next from 'i18next';
import {
  PinRelatedCategoryId,
  RequestCategoryAddGuestPIN,
  RequestCategoryRemoveGuestPIN,
  RequestCategoryChangePIN
} from './constant';
import {
  searchTable,
  setSearchField,
  setSearchPagination,
  setSearchState,
  setShouldClearQuery
} from '../../redux/actions/search';
import { ENTITY } from '../../utils/searchConstant';
import { HeaderText, ModalHeader, StyledModal, StyledModalBody } from '../../components/Modals';
import { Select, SelectItem } from '../../components/ui/Select';
import { SettingsModalInput } from '../settings/SettingsModals';
import styled from 'styled-components';
import { TextArea } from '../../components/ui/TextArea';
import { CustomDatePicker } from '../../components/CustomSearchBar';
import { SEARCH_TABLES } from '../../helper/constants';
import { Separator } from '../../components/ui/Separator';
import { ButtonBodyVariant } from '../../components/ui/ButtonVariant';
import { Flex } from '../../components/ui/Flex';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '../../components/ui/Form';
import { SettingsItemSubTitle } from '../settings/SettingsItem';

const ServiceCreate = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  moment.locale(i18n.resolvedLanguage, localization);
  const localLang = i18next.resolvedLanguage === 'fr' ? locale : localeEn;

  const searchState = useSelector((state) => state.search);
  const roleId = useSelector((state) => state.auth.roleId);
  const buildingId = useSelector((state) => Number.parseFloat(state.auth.building_id));
  const config = useSelector((state) => state.auth.config);
  const modal = useSelector((state) => state.headerModal.modal);
  const globalOrgId = useSelector((state) => state.Organization.globalOrgId);

  const { categories, error, searchStatus } = useSelector((state) => state.services);
  const { deviceSlots, user } = useSelector((state) => state.user);
  const { allBuildings } = useSelector((state) => state.building);
  const { AllOrganizations, organization } = useSelector((state) => state.Organization);

  const isOneOrganization =
    [UserRole.ADMIN, UserRole.MANAGER, UserRole.VENDOR].includes(roleId) && organization;
  const isOneBuilding = allBuildings?.length === 1;

  const [availableSlot, setAvailableSlot] = useState(null);
  const [userPinLen, setUserPinLen] = useState(0);
  const [localError, setLocalError] = useState('');
  const [isPrevPinReq, setIsPrevPinReq] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (config) {
      if (roleId != UserRole.SUPER_ADMIN) {
        setUserPinLen(config['user_pin_len']);
      }
    }
  }, [config]);

  const isPinCategory = (categoryString) => {
    if (!categoryString || typeof categoryString !== 'string') {
      return false;
    }
    return parseInt(categoryString.split(',')[0], 10) === PinRelatedCategoryId;
  };

  const _verifyPinCategory = (type) => (categoryString) => {
    if (!categoryString || typeof categoryString !== 'string') {
      return false;
    }
    const categoryInfo = categoryString.split(',');
    if (!parseInt(categoryString.split(',')[0], 10) === PinRelatedCategoryId) {
      return false;
    }

    return categoryInfo[1] === type;
  };

  const isRemoveGuestPin = _verifyPinCategory(RequestCategoryRemoveGuestPIN);

  const isAddGuestPin = _verifyPinCategory(RequestCategoryAddGuestPIN);

  const isChangePin = _verifyPinCategory(RequestCategoryChangePIN);

  const schema = Yup.object().shape({
    user: Yup.object()
      .required(t('User is required'))
      .test('is-required', t('User is required'), (value, context) => {
        if (!value || value == '' || value?.value < 1) return false;
        return true;
      })
      .test('no-available-slot', '', (value, context) => {
        if (deviceSlots && deviceSlots.length === 0) {
          if (isAddGuestPin(context.parent.category) && value && value?.value > 0) {
            if (localError != '') {
              return false;
            }
            return true;
          }
        }
        return true;
      }),
    removeSlot: Yup.string().test(
      'is-required',
      t('Select a guest to remove'),
      (value, context) => {
        if (isRemoveGuestPin(context.parent.category)) {
          if (value && value.split('-')[0] >= 2) return true;
          return false;
        }
        return true;
      }
    ),

    guest: Yup.string().test('is-required', t('Enter a guest name'), (value, context) => {
      if (isAddGuestPin(context.parent.category)) {
        return value != '' && value != null;
      }
      return true;
    }),

    building_id: Yup.string()
      .required(t('Select a building'))
      .test('is-required', t('Select a building'), (value, context) => {
        if (!value || value == '' || parseInt(value) < 1) return false;
        return true;
      }),
    subject: Yup.string()
      .max(64, t('Not more than 64 characters'))
      .required(t('Enter the subject')),
    category: Yup.string()
      .required(t('Select a category'))
      .test('is-required', t('Select a category'), (value, context) => {
        if (!value || value == '' || parseInt(value) < 1) return false;
        return true;
      }),
    description: Yup.string()
      .max(500, t('Not more than 500 characters'))

      .test('is-pin-required', t('Enter a PIN number'), (value, context) => {
        if (isAddGuestPin(watchCategory) || isChangePin(watchCategory)) {
          if (isPinCategory(context.parent.category)) {
            if (value == '' || !value) return false;
            return true;
          }
          return true;
        }
        return true;
      })

      .test(
        'not-repetition',
        t("Can't include duplicated numbers (i.e 3333)"),
        (value, context) => {
          if (!isPinCategory(context.parent.category)) return true;

          if (/(\d)\1{3}/g.test(value)) {
            return false;
          }
          return true;
        }
      )

      .test('not-sequence', t("Can't include consecutive numbers (i.e 1234)"), (value, context) => {
        const categoryType = context.parent.category?.split(',')[0];
        if (categoryType != categoryTypes.SECURITY) return true;
        if (
          /(?=0123|1234|2345|3456|4567|5678|6789|9876|8765|7654|6543|5432|4321|3210)/g.test(value)
        ) {
          return false;
        }
        return true;
      })

      .test('only-numbers', t('Only numbers are allowed for PIN'), (value, context) => {
        if (isAddGuestPin(context.parent.category) || isChangePin(context.parent.category)) {
          if (/^[0-9]*$/g.test(value)) return true;
          return false;
        }
        return true;
      })

      .test(
        'user_pin_len',
        t('pin_number', { userPinLen: userPinLen }),

        (value, context) => {
          if (userPinLen === 0) return true;

          if (isAddGuestPin(context.parent.category) || isChangePin(context.parent.category)) {
            if (value && value.length != userPinLen) {
              return false;
            }
            return true;
          }
          return true;
        }
      )

      .test('is-required', t('Give a short description'), (value, context) => {
        if (
          isAddGuestPin(context.parent.category) ||
          isChangePin(context.parent.category) ||
          isRemoveGuestPin(context.parent.category)
        )
          return true;

        if (!value) return false;
        return value.length > 0;
      }),

    scheduledDate: Yup.string().required('Schedule a date').nullable(),

    contact_type: Yup.number()
      .required(t('Select how to contact you'))
      .test('is-required', t('Select how to contact you'), (value, context) => {
        if (!value || value == '' || parseInt(value) < 1) return false;
        return true;
      })

      .test(
        'resource-not-exist',
        t("Resident doesn't have this contact type defined"),
        (value, context) => {
          if (user?.email || user?.cell_phone) {
            switch (parseInt(value)) {
              case 1:
                if (!user?.email || user?.email === '') return false;
                break;
              case 2:
                if (!user?.cell_phone || user?.cell_phone === '') return false;
                break;
              default:
                break;
            }
          }
          return true;
        }
      ),
    priority: Yup.number()
      .required(t('Select your urgency'))
      .test('is-required', t('Select you urgency'), (value, context) => {
        if (!value || value == '' || parseInt(value) < 1) return false;
        return true;
      }),
    organization: Yup.string().test(
      'org-required',
      t('Select an organization'),
      (value, context) => {
        if (roleId == UserRole.SUPER_ADMIN) {
          return value != null && value != '' && parseInt(value) > 0;
        }
        return true;
      }
    )
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      category: undefined,
      subject: '',
      removeSlot: -1,
      organization: globalOrgId,
      ...(isOneBuilding ? { building_id: buildingId } : {})
    }
  });

  const watchUser = form.watch('user');
  const watchCategory = form.watch('category');
  const watchOrganization = form.watch('organization');
  const watchBuilding = form.watch('building_id');

  function disabledDate(current) {
    // Can not select days before today
    return current < moment().startOf('day');
  }

  const onClose = () => {
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
    dispatch(clearServiceError());
  };

  const customSubmit = (values) => {
    setIsSubmitting(true);
    let removeGuestPIN = 0;
    let slot_pin_name = '';
    if (values.removeSlot) {
      for (const slot of deviceSlots) {
        if (parseInt(slot.slot) === parseInt(values.removeSlot.split('-')[0])) {
          removeGuestPIN = slot.code;
          slot_pin_name = slot.slot_pin_name;
        }
      }
    }
    const myCategory = parseInt(values.category.split(',')[0], 10);
    const mySubCategory = values.category.split(',')[1];

    let myDescription = values.description;
    let slot = 0;
    let action;
    let type;
    let name;
    switch (mySubCategory) {
      case RequestCategoryChangePIN:
        name = '';
        type = 'user';
        action = 'change_pin';
        slot = 1 - 1;
        break;
      case RequestCategoryAddGuestPIN:
        name = values?.guest;
        type = 'guest';
        action = 'add';
        slot = parseInt(availableSlot) - 1; // use next available slot
        break;
      case RequestCategoryRemoveGuestPIN:
        name = slot_pin_name;
        type = 'guest';
        action = 'delete';
        slot = parseInt(values.removeSlot) - 1; // use the slot selected by user
        myDescription = removeGuestPIN;
        break;
      default:
        slot = 0;
    }

    let myContactInfo;
    switch (parseInt(values.contact_type)) {
      case 1:
        myContactInfo = user?.email;
        break;
      case 2:
        myContactInfo = user?.cell_phone;
        break;
      default:
        myContactInfo = null;
    }

    let subValues = {
      user_id: parseInt(values.user?.value), //  values.user_id
      building_id: parseInt(values.building_id),
      subject: values.subject,
      category_id: '',
      category: myCategory,
      description: myDescription,
      media: '',
      last_update: '',
      create_date: '',
      scheduled_date: moment(values.scheduledDate).utcOffset(0, true).toISOString(),
      status: null,
      assigned_to: values.assignedTo ? parseInt(values.assignedTo.value) : null,
      time_slot: null,
      close_date: '',
      contact_type: parseInt(values.contact_type),
      priority: parseInt(values.priority),
      allow_access: false,
      contact_info: myContactInfo
    };
    let data = {};
    if (myCategory === PinRelatedCategoryId) {
      data = {
        action: action,
        type: type,
        name: name,
        status: 'Requested',
        slot: slot
      };
    }

    subValues = {
      ...subValues,
      data: data
    };
    dispatch(
      createService({
        body: subValues,
        success: () => {
          dispatch(getServices({ id: 0, page: 1, orgId: globalOrgId, status: searchStatus }));
          dispatch(changeOpenedModal({ modal: '' }));
          dispatch(setSearchField({ entity: ENTITY.request, value: null }));
          dispatch(setShouldClearQuery(true));
          dispatch(
            searchTable({
              entity: SEARCH_TABLES.WORK_REQUESTS,
              orgID: organization.id,
              body: {
                search: searchState.search,
                minCreationDate: searchState.minCreationDate,
                maxCreationDate: searchState.maxCreationDate,
                filters: searchState.filters,
                ordering: searchState.ordering,
                pagination: searchState.pagination
              },
              success: (res) => {
                dispatch(
                  setSearchState({
                    currentData: res.data.data ?? [],
                    currentPaginationData: res.data.pagination
                  })
                );

                if (!res.data.data) {
                  dispatch(setUserSearch({ page: 1 }));
                  dispatch(
                    setSearchPagination({
                      page: 1,
                      limit: 20
                    })
                  );
                }
              }
            })
          );
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Service request has been successfully created!')
            })
          );
          setIsSubmitting(false);
        },

        fail: (res) => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: res?.data?.message
                ? res.data.message
                : t('Service request creation failed!'),
              modal: 'errorToast'
            })
          );
          setIsSubmitting(false);
        }
      })
    );
  };

  useEffect(() => {
    if (watchUser?.value) {
      dispatch(
        getUser({
          id: watchUser?.value
        })
      );
    }
  }, [watchUser, dispatch]);

  useEffect(() => {
    if (watchOrganization) {
      dispatch(
        getAllBuildings({
          id: parseInt(watchOrganization),
          simple: true
        })
      );
    }

    // Get user_pin_len or the selected organization
    if (watchOrganization) {
      const userPinLen = AllOrganizations.find((o) => o.id === parseInt(watchOrganization))?.config
        ?.user_pin_len;

      setUserPinLen(userPinLen);
    }
  }, [watchOrganization, dispatch]);

  useEffect(() => {
    if (userPinLen > 0 && roleId === UserRole.SUPER_ADMIN && form.watch('description')) {
      form.trigger('description');
    }
  }, [userPinLen]);

  useEffect(() => {
    if (isPinCategory(watchCategory)) {
      setIsPrevPinReq(true);
      switch (watchCategory.split(',')[1]) {
        case RequestCategoryChangePIN:
          form.setValue('subject', 'Change my PIN request');
          break;
        default:
          form.setValue('subject', '');
          break;
      }
    } else if (isPrevPinReq) {
      setIsPrevPinReq(false);
      form.setValue('subject', '');
    }
  }, [watchCategory, form.setValue]);

  useEffect(() => {
    if (isPinCategory(watchCategory)) {
      if (watchCategory.split(',')[1] === RequestCategoryAddGuestPIN) {
        dispatch(
          getUserDeviceSlots({
            id: watchUser?.value,
            no_codes: true,
            only_empty: true,
            first_slot: 1,
            last_slot: 11
          })
        );
      } else {
        dispatch(
          watchUser?.value > 0
            ? getUserDeviceSlots({
                id: watchUser?.value
              })
            : setUserDeviceSlots({
                deviceSlots: []
              })
        );
      }
    }
  }, [watchUser, dispatch, watchCategory, watchOrganization, watchBuilding]);

  useEffect(() => {
    if (deviceSlots && deviceSlots.length === 0) {
      if (isAddGuestPin(watchCategory) && watchUser) {
        setLocalError(t('There is no available slots'));

        setAvailableSlot(null);
      }
    } else {
      setLocalError('');
      setAvailableSlot(deviceSlots[0]?.slot);
    }
  }, [deviceSlots]);

  useEffect(() => {
    form.setValue('building_id', isOneBuilding ? allBuildings[0].id : null);
  }, [globalOrgId, watchOrganization, isOneBuilding]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(customSubmit)}>
        <AddServiceRequestModal
          show={modal === 'createService'}
          side
          onClose={onClose}
          closeOnBackdrop>
          <ModalHeader title={t('Add Work Request')} />
          <StyledModalBody id={'service-request-modal-body'}>
            <Flex direction="column" gap="24px">
              <Flex direction="column" gap="8px">
                <HeaderText>{t('Work Request Details')}</HeaderText>

                <FormField
                  control={form.control}
                  name="subject"
                  render={({ field }) => (
                    <FormItem alt>
                      <FormLabel alt>{t('Subject')}</FormLabel>
                      <FormControl row>
                        <SettingsModalInput
                          maxLength={64}
                          type="text"
                          placeholder={t('Write subject here...')}
                          autoComplete="off"
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem alt>
                      <FormLabel alt>{t('Description')}</FormLabel>
                      <FormControl>
                        <TextArea
                          maxLength={500}
                          rows={5}
                          type="text"
                          placeholder={t('Describe the problem you have...')}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="category"
                  render={({ field }) => (
                    <FormItem alt>
                      <FormLabel alt>{t('Category')}</FormLabel>
                      <FormControl>
                        <Select custom placeholder={'Please select'} {...field}>
                          {categories &&
                            categories.map((item) =>
                              config.org_allow_edits == false ? (
                                item.ID === 35 && (
                                  <SelectItem
                                    id={`${item.Name},${item.SubCategory}`}
                                    key={`${item.ID},${item.SubCategory}`}
                                    value={`${item.ID},${item.SubCategory}`}>
                                    {item.Name}/{item.SubCategory}
                                  </SelectItem>
                                )
                              ) : (
                                <SelectItem
                                  id={`${item.Name},${item.SubCategory}`}
                                  key={`${item.ID},${item.SubCategory}`}
                                  value={`${item.ID},${item.SubCategory}`}>
                                  {item.Name}/{item.SubCategory}
                                </SelectItem>
                              )
                            )}
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {isAddGuestPin(watchCategory) && (
                  <FormField
                    control={form.control}
                    name="guest"
                    render={({ field }) => (
                      <FormItem alt>
                        <FormLabel alt>{t('Guest name')}</FormLabel>
                        <FormControl>
                          <SettingsModalInput placeholder={t('Guest name')} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}

                <Flex gap="24px">
                  <FormField
                    control={form.control}
                    name="contact_type"
                    render={({ field }) => (
                      <FormItem alt>
                        <FormLabel alt>{t('Method of contact')}</FormLabel>
                        <FormControl>
                          <Select placeholder={'Please select'} {...field}>
                            {CONTACT_WITH_MAP.map((item) => (
                              <SelectItem key={item.id} value={item.id}>
                                {item.value}
                              </SelectItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="priority"
                    render={({ field }) => (
                      <FormItem alt>
                        <FormLabel alt>{t('Urgency')}</FormLabel>
                        <FormControl>
                          <Select placeholder={'Please select'} {...field}>
                            {PRIORITY_MAPPING.map((item) => (
                              <SelectItem key={item.id} value={item.id}>
                                {item.value}
                              </SelectItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </Flex>
              </Flex>

              <Separator orientation="horizontal" />

              <Flex direction="column" gap="8px">
                <HeaderText>{t('Resident details')}</HeaderText>

                <Flex gap="24px">
                  <FormField
                    control={form.control}
                    name="organization"
                    render={({ field }) => (
                      <FormItem alt>
                        <FormLabel alt>{t('Organization')}</FormLabel>
                        {isOneOrganization && (
                          <SettingsItemSubTitle>{organization.name}</SettingsItemSubTitle>
                        )}
                        {AllOrganizations && AllOrganizations.length > 0 && (
                          <FormControl>
                            <Select
                              {...field}
                              onChange={(e) => {
                                form.resetField('building_id');
                                form.resetField('user');
                                form.resetField('assignedTo');
                                form.resetField('scheduledDate');
                                if (isRemoveGuestPin(watchCategory)) form.resetField('removeSlot');
                                field.onChange(e);
                              }}
                              placeholder={'Select organization'}>
                              <SelectItem value={-1}>{t('Select organization')}</SelectItem>
                              {AllOrganizations.map((item, index) => (
                                <SelectItem value={item.id} key={index}>
                                  {item.name}
                                </SelectItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}

                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="building_id"
                    render={({ field }) => (
                      <FormItem alt>
                        <FormLabel alt> {t('Building')}</FormLabel>
                        {isOneBuilding ? (
                          <SettingsItemSubTitle>{allBuildings[0].name}</SettingsItemSubTitle>
                        ) : (
                          <FormControl>
                            <Select
                              {...field}
                              disabled={roleId !== 99 ? false : !form.getValues('organization')}
                              placeholder={'Please select'}
                              onChange={(e) => {
                                form.resetField('user');
                                form.resetField('assignedTo');
                                form.resetField('scheduledDate');
                                if (isRemoveGuestPin(watchCategory)) form.resetField('removeSlot');
                                field.onChange(e);
                              }}>
                              {allBuildings?.length > 0 ? (
                                <SelectItem value={-1}>{t('Please select')}</SelectItem>
                              ) : (
                                <SelectItem value={-1}>{t('No Buildings Available')}</SelectItem>
                              )}

                              {allBuildings.map((building) => (
                                <SelectItem key={building.id} value={building.id}>
                                  {building.name}
                                </SelectItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </Flex>

                <FormField
                  control={form.control}
                  name="user"
                  render={({ field }) => (
                    <FormItem alt>
                      <FormLabel alt>{t('Resident')}</FormLabel>
                      <FormControl>
                        <UserAsyncDropDown
                          {...field}
                          skip_deleted={true}
                          onChange={(e) => {
                            if (isRemoveGuestPin(watchCategory)) form.resetField('removeSlot');
                            field.onChange(e);
                          }}
                          type={1}
                          buildingId={watchBuilding}
                          only_residents={
                            parseInt(watchCategory?.split(',')[0], 10) === PinRelatedCategoryId
                          }
                          residentOnly={
                            parseInt(watchCategory?.split(',')[0], 10) === PinRelatedCategoryId
                          }
                          skip_coresidents={
                            parseInt(watchCategory?.split(',')[0], 10) === PinRelatedCategoryId
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {isRemoveGuestPin(watchCategory) && (
                  <FormField
                    control={form.control}
                    name="removeSlot"
                    render={({ field }) => (
                      <FormItem alt>
                        <FormLabel alt>{t('Select guest PIN to remove')}</FormLabel>
                        <FormControl>
                          <Select {...field}>
                            {deviceSlots
                              .filter((slot) => slot.slot >= 2)
                              .map((slot) => {
                                return (
                                  <SelectItem
                                    id={`${slot?.slot}-${slot?.device_id}-${slot?.slot_pin_name}`}
                                    key={`${slot?.slot}-${slot?.device_id}-${slot?.slot_pin_name}`}
                                    value={`${slot?.slot}-${slot?.device_id}-${slot?.slot_pin_name}`}>
                                    {slot?.slot_pin_name}
                                  </SelectItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
              </Flex>

              <Separator orientation="horizontal" />

              <Flex direction="column" gap="8px">
                <HeaderText>{t('Service details')}</HeaderText>

                <Flex gap="24px">
                  <FormField
                    control={form.control}
                    name="assignedTo"
                    render={({ field }) => (
                      <FormItem alt>
                        <FormLabel alt>{t('Assigned to')}</FormLabel>
                        <FormControl>
                          <UserAsyncDropDown
                            {...field}
                            noUser={true}
                            skip_deleted={true}
                            type={2}
                            placeholder={t('Please select')}
                            buildingId={watchBuilding}
                            error={error}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="scheduledDate"
                    render={({ field }) => (
                      <FormItem alt>
                        <FormLabel alt>{t('Scheduled on')}</FormLabel>
                        <FormControl>
                          <CustomDatePicker
                            {...field}
                            className="form-control"
                            width={'100%'}
                            height={'56px'}
                            disabledDate={disabledDate}
                            showTime={{
                              format: DATE_FORMATS.hh_mm_a
                            }}
                            id="scheduledDate"
                            use12Hours
                            showSecond={false}
                            format={DATE_FORMATS.MMMM_DD_YYYY}
                            placeholder={t('Select date')}
                            locale={{
                              ...localLang,
                              lang: {
                                ...localLang.lang,
                                ok: 'OK'
                              }
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </Flex>
              </Flex>
            </Flex>
          </StyledModalBody>
          <ButtonBodyVariant type="submit">{t('Confirm')}</ButtonBodyVariant>
        </AddServiceRequestModal>
      </form>
    </Form>
  );
};

const AddServiceRequestModal = styled(StyledModal)`
  .modal-content {
    padding: 20px 24px 40px 24px !important;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  }

  .modal-dialog .modal-content .modal-header .modal-title {
    font-size: 25px;
    line-height: 40px;
  }
`;

export default ServiceCreate;
