import 'rc-time-picker/assets/index.css';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TenantDetail from '../../components/TenantDetail';
import { ReactComponent as Details } from '../../assets/icons/details.svg';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Icon, myIcons } from '../../helper/icons';
import { InputSeparator, SettingsModalBoldText } from '../settings/SettingsModals';
import {
  SettingsItem,
  SettingsItemContent,
  SettingsItemSubTitle,
  SettingsItemTitle
} from '../settings/SettingsItem';
import { FaBuilding, FaCalendar, FaUser } from 'react-icons/fa';
import { Palette } from '../../components/utils';
import { IoMdCheckmark } from 'react-icons/io';
import styled from 'styled-components';
import { FaHouse } from 'react-icons/fa6';
import { HiOutlineSquare3Stack3D } from 'react-icons/hi2';
import { getRoomUnitTerminology } from '../../utils/utils';
import { IoChatboxEllipses } from 'react-icons/io5';

const BookingDetails = ({ buildings, status, booking, users }) => {
  const { amenityTypes } = useSelector((state) => state.amenities);
  const { organization } = useSelector((state) => state.Organization);

  const { currentAmenityType } = useSelector((state) => state.services);
  const { t } = useTranslation();

  const STATUS_MAP = [
    {
      id: 0,
      value: t('New')
    },
    {
      id: 1,
      value: t('Open')
    },
    {
      id: 2,
      value: t('Pending')
    },
    {
      id: 3,
      value: t('Cancelled')
    },
    {
      id: 4,
      value: t('Closed')
    },
    {
      id: 5,
      value: t('Complete')
    },
    {
      id: 6,
      value: t('Reserved')
    },
    {
      id: 7,
      value: t('Booked')
    },
    {
      id: 8,
      value: t('Enabled')
    },
    {
      id: 9,
      value: t('Disabled')
    },
    {
      id: 10,
      value: t('Sent')
    },
    {
      id: 11,
      value: t('Processing')
    },
    {
      id: 12,
      value: t('Error')
    },
    {
      id: 13,
      value: t('In Progress')
    },
    {
      id: 14,
      value: t('deleted')
    }
  ];

  const bookingDetailLabels = {
    status: t('Status'),
    parking_spot: t('Parking spot'),
    elevator: t('Elevator'),
    amenity: t('Amenity')
  };

  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endDate, setEndDate] = useState('');
  const [endTime, setEndTime] = useState('');

  useEffect(() => {
    let title = t('All');
    amenityTypes.forEach((amenityType) => {
      if (amenityType.ID == currentAmenityType) {
        title = amenityType.Description;
      }
    });

    setTitle(title);
  }, [amenityTypes, currentAmenityType]);

  useEffect(() => {
    setStartDate(moment(booking.start_date).utc().format('MMMM Do YYYY'));
    setStartTime(moment(booking.start_date).utc().format('hh:mm a'));

    if (moment(booking.end_date).utc().format('HH:mm') === '00:00') {
      setEndDate(moment(booking.end_date).subtract(1, 'days').utc().format('MMMM Do YYYY'));
    } else {
      setEndDate(moment(booking.end_date).utc().format('MMMM Do YYYY'));
    }

    if (moment(booking.end_date).utc().format('HH:mm') === '23:59') {
      setEndTime('12:00 am');
    } else {
      setEndTime(moment(booking.end_date).utc().format('hh:mm a'));
    }
  }, [booking]);

  const filterObject = (array, id) => {
    if (array && id) {
      const resultArray = array.filter((obj) => obj.id === id);
      return resultArray[0];
    }
    return undefined;
  };

  const amenityType = amenityTypes.find(
    (a) => parseInt(a.ID) === parseInt(booking['amenity_type'])
  );

  return (
    <div className="subBodyDiv">
      <div className="bookingTenantDiv">
        <Container>
          <SettingsItem>
            <IoMdCheckmark size={18} color={Palette.HAZY_BLUE} className="flex-shrink-0" />
            <SettingsItemContent>
              <SettingsItemTitle>{bookingDetailLabels.status}</SettingsItemTitle>
              <SettingsItemSubTitle>
                {(filterObject(STATUS_MAP, booking.status) &&
                  filterObject(STATUS_MAP, booking.status).value) ||
                  'Unknown'}
              </SettingsItemSubTitle>
            </SettingsItemContent>
          </SettingsItem>

          {booking && amenityTypes && (
            <SettingsItem>
              {myIcons[parseInt(booking['amenity_type'])] ? (
                <BookingsIcon icon={myIcons[parseInt(booking['amenity_type'])]} />
              ) : (
                <DefaultIcon />
              )}{' '}
              <SettingsItemContent>
                <SettingsItemTitle>
                  {amenityType ? amenityType.Description : 'Not Found'}
                </SettingsItemTitle>
                <SettingsItemSubTitle>{booking.title}</SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>
          )}

          <SettingsItem>
            <FaCalendar size={18} color={Palette.HAZY_BLUE} className="flex-shrink-0" />
            <SettingsItemContent>
              <SettingsItemTitle>{t('Book date and time')}</SettingsItemTitle>
              <SettingsItemSubTitle>
                <p>{`${startDate} ${startTime}`}</p>
                <p className="m-0">{`${endDate} ${endTime}`}</p>
              </SettingsItemSubTitle>
            </SettingsItemContent>
          </SettingsItem>

          {parseInt(booking.amenity_type) === 3 && (
            <SettingsItem>
              <FaBuilding size={18} color={Palette.HAZY_BLUE} className="flex-shrink-0" />
              <SettingsItemContent>
                <SettingsItemTitle>{t('Vehicle information')}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {booking.data.make},{' ' + booking.data.model},{' ' + booking.data.color},{' '}
                  {' ' + booking.data.plate}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>
          )}

          {booking.data?.comments && <SettingsItem>
            <IoChatboxEllipses size={18} color={Palette.HAZY_BLUE} className="flex-shrink-0" />
            <SettingsItemContent>
              <SettingsItemTitle>Additional Comments</SettingsItemTitle>
              <SettingsItemSubTitle>
                {booking.data?.comments}
              </SettingsItemSubTitle>
            </SettingsItemContent>
          </SettingsItem>
          }

          <InputSeparator rowGap={'20px'}>
            <SettingsModalBoldText fontSize={'24px'}>{t('Resident details')}</SettingsModalBoldText>

            <SettingsItem>
              <FaBuilding size={18} color={Palette.HAZY_BLUE} className="flex-shrink-0" />
              <SettingsItemContent>
                <SettingsItemTitle>{t('Building')}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {booking && booking.tenant ? booking.tenant.building : 'none'}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <HiOutlineSquare3Stack3D
                size={18}
                color={Palette.HAZY_BLUE}
                className="flex-shrink-0"
              />
              <SettingsItemContent>
                <SettingsItemTitle>{t('Floor')}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {booking && booking.tenant ? booking.tenant.floor : 'none'}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <FaHouse size={18} color={Palette.HAZY_BLUE} className="flex-shrink-0" />
              <SettingsItemContent>
                <SettingsItemTitle>{getRoomUnitTerminology(organization)}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {booking && booking.tenant ? booking.tenant.unit_num : 'none'}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <FaUser size={18} color={Palette.HAZY_BLUE} className="flex-shrink-0" />
              <SettingsItemContent>
                <SettingsItemTitle>{t('Resident')}</SettingsItemTitle>
                <SettingsItemSubTitle>{booking.tenant && booking.tenant.name}</SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            {/* <TenantDetail
              building={booking && booking.tenant ? booking.tenant.building : 'none'}
              floor={booking && booking.tenant ? booking.tenant.floor : 'none'}
              unit={booking && booking.tenant ? booking.tenant.unit_num : 'none'}
              name={booking.tenant && booking.tenant.name}
            /> */}
          </InputSeparator>
        </Container>
      </div>
    </div>
  );
};

const DefaultIcon = styled(Details)`
  width: 18px;
  height: 18px;
`;

const BookingsIcon = styled(Icon)`
  width: 18px;
  height: 18px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default BookingDetails;
