import React, { useMemo } from 'react';
import {
  SidebarNavTitle,
  StyledBottomSidebarNavItem,
  StyledHrefLink,
  StyledLink,
  StyledSidebarNavDropdown,
  StyledSidebarNavItem
} from './SidebarComponents';
import { closeActiveModal, openModal } from '../../helper/modals';
import { MdAnalytics, MdBookmarkAdded, MdOutlineSettings, MdSpaceDashboard } from 'react-icons/md';
import { BsBuildingsFill } from 'react-icons/bs';
import { FaTools, FaTruck, FaUsers } from 'react-icons/fa';
import { LuArrowUpRight, LuNewspaper } from 'react-icons/lu';
import { IoIosHelpCircle } from 'react-icons/io';
import { SiGoogleanalytics } from 'react-icons/si';
import { VscOrganization } from 'react-icons/vsc';
import { BiSolidCoupon } from 'react-icons/bi';
import { PiVideoCamera } from 'react-icons/pi';
import { CSidebarNavDivider } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeOpenedModal } from '../../redux/actions/headerModal';
import { whiteListedDomains } from '../../helper/constants';
import { useHistory, useLocation } from 'react-router-dom';
import { logout as logoutAction } from '../../redux/actions/auth';
import { UserRole } from '../../views/users/constant';
import styled from 'styled-components';

// PROD: Charlesgate 51, Grata 66,
// DEV : 1 Grata,
const ALLOWED_SECURITY_ORGS = {
  'gratasys-dev': [1],
  gratasys: [51, 66]
};

/* 
Action Types: link, dropdown, action
  link: Link to redirect user
  dropdown: Navigation dropdown, leads to other links (news)
  action: Click action - Open modals, logout
*/
const DropdownSidebarComponent = ({ title, icon, children, location }) => {
  return (
    <StyledSidebarNavDropdown name={title} icon={icon}>
      {children.map((child) => {
        return (
          <StyledLink key={child.title} to={child.to}>
            <StyledSidebarNavItem selected={location.pathname === child.to}>
              {child.title}
            </StyledSidebarNavItem>
          </StyledLink>
        );
      })}
    </StyledSidebarNavDropdown>
  );
};

const LinkSidebarComponent = ({ title, icon, to, onClick, location }) => {
  return (
    <StyledLink
      to={to}
      onClick={() => {
        closeActiveModal();
        if (onClick) onClick();
      }}>
      <StyledSidebarNavItem selected={location.pathname === to}>
        {icon} {title}
      </StyledSidebarNavItem>
    </StyledLink>
  );
};

const ActionSidebarComponent = ({ title, icon, onClick }) => {
  return (
    <a onClick={onClick}>
      <StyledSidebarNavItem>
        {icon} {title}
      </StyledSidebarNavItem>
    </a>
  );
};

const BottomActionSidebarComponent = ({ title, icon, to, onClick }) => {
  return (
    <a onClick={onClick} href={to} target="_blank" rel="noopener noreferrer">
      <StyledBottomSidebarNavItem>
        {title} {icon}
      </StyledBottomSidebarNavItem>
    </a>
  );
};

const renderNavItem = ({ navigationItem, ...rest }) => {
  switch (navigationItem.type) {
    case 'link':
      return <LinkSidebarComponent {...navigationItem} {...rest} />;
    case 'action':
      return <ActionSidebarComponent {...navigationItem} {...rest} />;
    case 'dropdown':
      return <DropdownSidebarComponent {...navigationItem} {...rest} />;
  }
};

export const DefaultSidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { roleId } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.profile);
  const org = useSelector((state) => state.Organization);
  const { config } = useSelector((state) => state.auth);

  const openHelpModal = () => {
    dispatch(changeOpenedModal({ modal: 'helpModal' }));
  };

  const removeLogoutItems = () => {
    localStorage.clear();
    history.push('/login');
    window.location.reload();
  };

  const logout = () => {
    dispatch(
      logoutAction({
        success: () => {
          removeLogoutItems();
        },
        fail: () => {
          removeLogoutItems();
        }
      })
    );
  };

  const navigation = useMemo(() => {
    const navigation = [
      {
        title: '',
        tabs: [
          {
            type: 'link',
            title: t('Dashboard'),
            to: '/dashboard',
            icon: <MdSpaceDashboard size={23} />
          }
        ]
      },

      {
        title: 'My Buildings',
        tabs: [
          {
            type: 'link',
            title: t('My Buildings'),
            to: '/buildings',
            icon: <BsBuildingsFill size={23} />
          },
          {
            type: 'link',
            title: t('Users'),
            to: '/users',
            icon: <FaUsers size={23} />
          },
          {
            type: 'link',
            title: t('Work Requests'),
            to: '/requests',
            icon: <FaTools size={23} />
          },
          {
            type: 'dropdown',
            title: t('News'),
            icon: <LuNewspaper size={23} />,
            children: [
              {
                title: t('Templates'),
                to: '/conversation/templates'
              },
              {
                title: t('Drafts'),
                to: '/conversation/drafts'
              },
              {
                title: t('Sent'),
                to: '/conversation/sent'
              }
            ]
          },
          {
            type: 'link',
            title: t('Bookings'),
            to: '/bookings',
            icon: <MdBookmarkAdded size={23} />
          }
        ]
      },

      {
        title: 'Reports',
        tabs: [
          {
            type: 'link',
            title: t('Building Analytics'),
            to: '/reports',
            icon: <MdAnalytics size={23} />
          }
        ]
      },

      {
        title: 'Settings',
        tabs: [
          {
            type: 'link',
            title: t('My Profile'),
            to: '/settings',
            icon: <MdOutlineSettings size={23} />
          },

          {
            type: 'action',
            title: t('Help'),
            icon: <IoIosHelpCircle size={23} />,
            onClick: () => {
              openHelpModal();
            }
          }
        ]
      }
    ];

    if (roleId === UserRole.SUPER_ADMIN || roleId === UserRole.ADMIN) {
      navigation[2].tabs.splice(3, 0, {
        type: 'link',
        title: t('PM Analytics'),
        to: '/pm-analytics',
        icon: <SiGoogleanalytics size={23} />
      });
    }

    if (roleId === UserRole.SUPER_ADMIN) {
      navigation[1].tabs.splice(0, 0, {
        type: 'link',
        title: t('Organizations'),
        to: '/organization',
        icon: <VscOrganization size={23} />
      });

      navigation[2].tabs.splice(3, 0, {
        type: 'link',
        title: t('Deals'),
        to: '/deals',
        icon: <BiSolidCoupon size={23} />
      });
    }

    //Check if Deliveries feature is enabled for the org
    if (roleId === UserRole.SUPER_ADMIN || config.enable_deliveries) {
      navigation[1].tabs.splice(4, 0, {
        type: 'link',
        title: t('Deliveries'),
        to: '/deliveries',
        icon: <FaTruck size={23} />
      });
    }

    if (
      ALLOWED_SECURITY_ORGS[process.env.REACT_APP_PROJECT_ID].includes(parseInt(org.globalOrgId))
    ) {
      navigation[1].tabs.splice(2, 0, {
        type: 'link',
        title: t('PM Security'),
        to: '/security',
        icon: <PiVideoCamera size={23} />
      });
    }
    return navigation;
  }, [roleId, org.globalOrgId, profile?.email]);

  const bottomNavigation = useMemo(() => {
    const navigation = [
      {
        type: 'href-link',
        title: t('Terms and Conditions'),
        to: 'https://www.grata.life/terms-and-conditions',
        icon: <LuArrowUpRight size={13} />
      },
      {
        type: 'href-link',
        title: t('Privacy Policy'),
        to: 'https://www.grata.life/privacy-policy',
        icon: <LuArrowUpRight size={13} />
      },
      {
        type: 'action',
        title: t('Logout'),
        onClick: () => {
          logout();
        }
      }
    ];

    if (roleId === UserRole.SUPER_ADMIN) {
      navigation.splice(2, 0, {
        type: 'action',
        title: t('Change Data Center'),
        onClick: () => {
          openModal('ModalEditDataCenter');
        }
      });
    }

    return navigation;
  }, []);

  return (
    <>
      <SidebarNavigationContainer>
        {navigation.map((item) => {
          return (
            <div key={item.title}>
              <SidebarNavTitle>{item.title}</SidebarNavTitle>
              <div>
                {item.tabs.map((tab) => {
                  return (
                    <React.Fragment key={tab.title}>
                      {renderNavItem({
                        navigationItem: tab,
                        location
                      })}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          );
        })}
      </SidebarNavigationContainer>
      <CSidebarNavDivider />
      {bottomNavigation.map((item) => {
        return (
          <BottomActionSidebarComponent
            key={item.title}
            title={item.title}
            icon={item.icon}
            to={item.to}
            onClick={item.onClick}
          />
        );
      })}
    </>
  );
};

const SidebarNavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
