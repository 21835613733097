import { Tooltip } from 'antd';
import { DatePicker, Space } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Fonts, Palette } from './utils';
import { FiSearch } from 'react-icons/fi';
import Select, { components } from 'react-select';
import i18next from 'i18next';
import locale from 'antd/es/date-picker/locale/fr_FR';
import localeEn from 'antd/es/date-picker/locale/en_US';
import moment from 'moment';
import { setSearchField, setShouldClearQuery } from '../redux/actions/search';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import { DATE_FORMATS } from '../views/users/constant';
import { TextInput } from './ui/TextInput';
import { IoIosCheckmark } from 'react-icons/io';
import { createFilter } from 'react-select/dist/react-select.cjs.prod';
import { getRoomUnitTerminology } from '../utils/utils';
import { ENTITY } from '../utils/searchConstant';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';

const CustomSearchBar = ({
  setValue,
  handleSearch,
  handleGet,
  entitySearch = null,
  searchOptions = null,
  setSelectedFieldToSearch,
  reset,
  shouldHide = false,
  showSelect = true,
  initialValue = ''
}) => {
  const { searchFields, shouldClearQuery, selectedField } = useSelector((state) => state.search);
  const { organization } = useSelector((state) => state.Organization);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const initialOption = {
    label: t('All'),
    value: 'all'
  };

  const [selectedOption, setSelectedOption] = useState(initialOption);
  const [searchText, setSearchText] = useState(initialValue || '');
  const [mySearchFields, setMySearchFields] = useState([]);
  const [myDate, setMyDate] = useState(null);

  useEffect(() => {
    if (shouldClearQuery) {
      if (reset && typeof reset === 'function') {
        reset();
      }
      setSearchText('');
      setValue('');
      setMyDate(null);
      dispatch(setShouldClearQuery(false));
    }
  }, [shouldClearQuery]);

  useEffect(() => {
    setSelectedOption(selectedField[entitySearch] || initialOption);
  }, [selectedField, setSelectedOption]);

  const handleSetSearchField = (selectedField) => {
    setValue('');
    setSearchText('');
    setMyDate(null);
    if (searchOptions) {
      setSelectedFieldToSearch(selectedField.value === 'all' ? null : selectedField.value);
    }
    setSelectedOption(selectedField);
    handleGet();

    if (selectedField?.value === 'all') {
      dispatch(setSearchField({ entity: entitySearch, value: null }));
    } else {
      dispatch(setSearchField({ entity: entitySearch, value: selectedField }));
    }
  };

  const onChangeDate = (date, dateString) => {
    if (date === null) {
      if (reset && typeof reset === 'function') {
        reset();
      }
      setMyDate(null);
      setSearchText(null);
      handleGet();
      return;
    }

    setMyDate(moment(dateString, DATE_FORMATS.YYYY_MM_DD));
    setValue(dateString);

    setSearchText(dateString);
  };

  useEffect(() => {
    const newSearchFields = [{ label: t('All'), value: 'all' }];

    if (entitySearch === ENTITY.user) {
      setMySearchFields([
        { label: t('All'), value: 'all' },
        { label: 'First Name', value: 'first_name' },
        { label: 'Last Name', value: 'last_name' },
        { label: 'Mobile Phone Number', value: 'mobile_phone' },
        { label: 'Home Phone Number', value: 'home_phone' },
        { label: 'Email', value: 'email' },
        { label: getRoomUnitTerminology(organization), value: 'unit.unit_num' }
      ]);
      return;
    }
    if (searchOptions) {
      setMySearchFields(searchOptions);
    } else {
      if (searchFields[entitySearch]) {
        for (const field of searchFields[entitySearch]) {
          newSearchFields.push({
            ...field,
            label: t(field.label)
          });
        }
        setMySearchFields(newSearchFields);
      }
    }
    setSelectedOption((prev) => {
      let selectedField = null;
      if (prev.value === 'all') {
        selectedField = { label: t('All'), value: 'all' };
      } else {
        // it is guaranteed to have one matching value
        selectedField = searchFields[entitySearch].filter((field) => {
          return field.value === selectedOption.value;
        })[0];
        if (selectedField) {
          selectedField = {
            ...selectedField,
            label: t(selectedField.label)
          };
        } else {
          selectedField = { label: t('All'), value: 'all' };
        }
      }
      return selectedField;
    });
  }, [entitySearch, t, organization]);

  if (shouldHide) {
    return <></>;
  }
  return (
    <>
      {(selectedOption && selectedOption?.value === 'book_date::text') ||
      selectedOption?.value === 'create_date::text' ||
      selectedOption?.value === 'expire_date::text' ? (
        <Space direction="vertical">
          <CustomDatePicker
            locale={i18next.resolvedLanguage === 'fr' ? locale : localeEn}
            allowClear={true}
            value={myDate}
            disabledDate={(current) =>
              entitySearch !== ENTITY.booking && moment().isBefore(current)
            }
            onChange={onChangeDate}
          />
        </Space>
      ) : (
        <TextInput
          includedSearchIcon={true}
          type="search"
          id="form1"
          height="46px;"
          width="280px;"
          placeholder={t('Search')}
          aria-label={t('Search')}
          value={searchText}
          onChange={(e) => {
            let newValue = e.target.value;
            newValue = newValue.replace('#', '');

            setValue(newValue.replace('+', '%2B'));
            setSearchText(newValue);
            if (newValue === '') {
              if (reset && typeof reset === 'function') {
                reset();
              }
              handleGet();
            }
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !e.shiftKey && e.target.value.length) {
              handleSearch();
            }
          }}
          cssOverride={'padding: 0px 10px 0px 50px;'}
        />
      )}
      {showSelect && (
        <SelectContainer>
          <StyledSelect
            loadingMessage={() => t('Loading...')}
            defaultValue={selectedOption}
            value={selectedOption}
            onChange={handleSetSearchField}
            options={mySearchFields}
            styles={inputSelectCustomStyles()}
            className="react-select-container"
            classNamePrefix="react-select"
            components={{
              IndicatorSeparator: () => null,
              Option: IconOption
            }}
          />
        </SelectContainer>
      )}
      <Tooltip title={t('search')}>
        <CustomSearchButton
          onClick={() => handleSearch()}
          disabled={selectedOption === null || searchText === '' ? true : false}>
          <FiSearch size={20} color={Palette.HAZY_BLUE} />
        </CustomSearchButton>
      </Tooltip>
    </>
  );
};

const SelectContainer = styled.div`
  width: 200px;
`;

export const IconOption = (props) => (
  <components.Option className="flex justify-content-between" {...props}>
    {props.data.label}
    {props.isSelected && <IoIosCheckmark />}
  </components.Option>
);

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? <BiChevronDown size={24} /> : <BiChevronUp size={24} />}
    </components.DropdownIndicator>
  );
};

export const filterConfig = createFilter({
  ignoreCase: true,
  ignoreAccents: false,
  trim: false,
  matchFrom: 'start'
});

export const inputSelectCustomStyles = ({
  height = '46px',
  minHeight = '46px',
  padding = '0 20px'
} = {}) => {
  return {
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      fontFamily: Fonts.OPEN_SANS_FONT,
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      fontFamily: Fonts.OPEN_SANS_FONT,
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600,
      height: height,
      minHeight: minHeight,
      border: state.error ? `2px solid ${Palette.ERROR}` : 'none',
      backgroundColor: Palette.SOLITUDE,
      opacity: state.isDisabled ? '50%' : '100%',
      cursor: state.isDisabled ? 'not-allowed' : 'auto',
      borderRadius: '10px',
      boxShadow: 'none',
      padding: padding
    }),
    menu: (baseStyles, state) => ({
      ...baseStyles,
      marginTop: '-1px',
      backgroundColor: Palette.SOLITUDE,
      boxShadow: 'none',
      borderBottomLeftRadius: '15px',
      borderBottomRightRadius: '15px'
    }),
    indicatorSeparator: (baseStyles, state) => ({
      ...baseStyles,
      display: 'none'
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: Fonts.OPEN_SANS_FONT,
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600,
      color: Palette.BLACK_50,
      backgroundColor: Palette.SOLITUDE
    })
  };
};

const inputStyles = `
  pointer-events: all !important;

  .react-select__control {
    :hover {
      cursor: pointer;
    }

    :disabled {
      cursor: not-allowed;
      opacity: 50%;
    }
  }

  .react-select__single-value {
    margin-left: 0;
    color: ${Palette.BLACK_50};
  }

  .react-select__multi-value {
    background-color: ${Palette.STROKE_GREY};
    border-radius: 5px;
  }

  .react-select__multi-value__remove {
    svg {
      color: ${Palette.BLACK};
    }
  }

  .react-select__value-container {
    padding: 0;
  }

  .react-select__input {

    input {
      font-family: ${Fonts.OPEN_SANS_FONT} !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 24px !important;
    }
  }

  .react-select__control--menu-is-open {
    background-color: ${Palette.WHITE};
    color: ${Palette.BLACK} !important;
    border: ${Palette.BORDER_OUTLINE} solid 0.5px !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .react-select__single-value {
      color: ${Palette.BLACK};
    }

    .react-select__control {


      :hover {
        cursor: pointer;
      }

      :disabled {
        cursor: not-allowed;
        opacity: 50%;
      }
    }
  }

  .react-select__menu-notice--no-options {
    background-color: ${Palette.WHITE};
    color: ${Palette.BLACK} !important;
  }

  .react-select__menu-notice--loading {
    background-color: ${Palette.WHITE};
    color: ${Palette.BLACK} !important;
  }

  .react-select__option {   
    background-color: ${Palette.WHITE};
    color: ${Palette.BLACK};
    padding: 8px 20px;

    :hover {
      color: ${Palette.BLACK};
      background-color: ${Palette.CUSTOM_GRAY_2};
      cursor: pointer;
    }
  }

  .react-select__indicator {
    padding: 0;
  }

  .react-select__control--is-disabled {
    cursor: not-allowed;

    :hover {
      cursor: not-allowed;
    }
  }

  .react-select__menu-list {
    padding-bottom: 0;
    padding-top: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: ${Palette.BORDER_OUTLINE} solid 0.5px !important;
  }
`;

// TODO: Just pass width into inputStyles
export const StyledSelect = styled(Select)`
  ${inputStyles}
  width: ${({ width }) => width || '100%'};
`;

export const StyledAsyncPaginate = styled(AsyncPaginate)`
  ${inputStyles}
  width: ${({ width }) => width || '100%'};
`;

const CustomSearchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 23px;
  font: normal normal 600 18px 'Open Sans', sans-serif;
  border: 1px solid ${Palette.HAZY_BLUE};
  background-color: ${Palette.WHITE};
  cursor: pointer;
  color: ${Palette.HAZY_BLUE};
  height: 45px;
  width: 45px;
`;

export const CustomDatePicker = styled(DatePicker)`
  display: flex;
  height: ${({ height }) => height || '46px'};
  width: ${({ width }) => width || '200px'};
  background-color: ${Palette.SOLITUDE} !important;
  border-radius: 10px;
  border: 2px hidden ${Palette.SOLITUDE};
  padding: 0px 24px;

  :hover {
    cursor: pointer;
  }

  input {
    color: ${Palette.BLACK_50};
    font-family: ${Fonts.OPEN_SANS_FONT} !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    ::placeholder {
      color: ${Palette.CUSTOM_GRAY};
      white-space: nowrap;
    }
    :hover {
      cursor: pointer;
    }
  }

  &.ant-picker-disabled {
    opacity: 50%;
    cursor: not-allowed;

    input {
      :hover {
        cursor: not-allowed;
      }
    }
  }

  &.ant-picker-focused {
    box-shadow: none;
    border: ${Palette.BORDER_OUTLINE} solid 0.5px;
    background-color: ${Palette.WHITE} !important;
    color: ${Palette.BLACK} !important;

    input {
      color: ${Palette.BLACK} !important;
    }
  }

  .ant-picker-clear {
    background: none;
  }

  ${({ error }) => error && `border: ${Palette.ERROR} solid 2px !important;`}

  ${({ cssOverride }) => cssOverride};
`;

export default memo(CustomSearchBar);
