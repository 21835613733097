import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';

import { setToast } from '../../../redux/actions/window';
import { changeOpenedModal } from '../../../redux/actions/headerModal';
import { Flex } from '../../../components/ui/Flex';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '../../../components/ui/Form';
import { Select, SelectItem } from '../../../components/ui/Select';
import { SettingsModalInput } from '../../settings/SettingsModals';
import { ButtonBodyVariant } from '../../../components/ui/ButtonVariant';
import UserAsyncDropDown from '../../../components/UserAsyncDropDown';
import { formatMbFileSize, getAllSearchOptions } from '../../../utils/utils';
import { searchTable, setSearchState } from '../../../redux/actions/search';
import { SEARCH_TABLES } from '../../../helper/constants';
import { setUserSearch } from '../../../redux/actions/user';
import { setCarriers } from '../../../redux/actions/carriers';
import { createDelivery, updateDelivery, uploadDeliveryImage } from '../../../redux/actions/deliveries';
import UploadDeliveryImage from './UploadDeliveryImage';
import { ACCEPTED_IMG_MIME_TYPES, MAX_IMG_FILE_SIZE } from '../constants';

const AddEditDeliveryForm = ({ delivery = null }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { allBuildings } = useSelector((state) => state.building);
  const globalOrgId = useSelector((state) => state.Organization.globalOrgId);
  const organization = useSelector((state) => state.Organization.organization);
  const searchState = useSelector((state) => state.search);
  const { carriersList } = useSelector((state) => state.carriers);

  const [stepIndex, setStepIndex] = useState(0);

  const schema = Yup.object().shape({
    building_id: Yup.number().required(t('Select a building')).positive(t('Select a building')).integer(),
    user: Yup.object()
      // To handle null value when user async select dropdown is reset to null
      // Setting it to undefined will trigger required() validation
      .transform((value, originalValue) => originalValue === null ? undefined : value)
      .required(t('User is required'))
      .test('is-required', t('User is required'), (value, context) => {
        if (!value || value === '' || value?.value < 1) return false;
        return true;
      }),
    carrier_id: Yup.number().required(t('Select a carrier')).positive(t('Select a carrier')).integer(),
    weight: Yup.string(),
    dimensions: Yup.string(),
    delivery_image: Yup.mixed()
      .test('is-valid-type', t('Only JPG and PNG files are supported'), (value) => {
        if (!value) return true;
        return ACCEPTED_IMG_MIME_TYPES.includes(value.blob.type);
      })
      .test('is-valid-size', t('Maximum file size is', {maxFileSize: formatMbFileSize(MAX_IMG_FILE_SIZE)}), (value) => {
        if (!value) return true;
        return value.blob.size <= MAX_IMG_FILE_SIZE;
      })
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: delivery ? {
      building_id: delivery.building_id,
      user: { 
        value: delivery.user_id, 
        label: `${delivery.user_name}${delivery.unit_num ? `, Unit ${delivery.unit_num}` : ''}` 
      },
      carrier_id: delivery.carrier_id,
      delivery_image: null,
      weight: delivery.weight || "",
      dimensions: delivery.dimensions || ""
    } : {
      delivery_image: null,
      weight: "",
      dimensions: ""
    }
  });

  const buildingId = form.watch('building_id');

  const searchOptions = getAllSearchOptions(searchState);

  const closeModal = () => {
    dispatch(changeOpenedModal({ modal: '' }));
  }

  const goToNextStep = (e) => {
    // prevent submitting the form
    e.preventDefault();
    setStepIndex((currentStep) => currentStep + 1);
  }

  const getDeliveries = () => {
    const searchBody = { ...searchOptions };
    dispatch(
      searchTable({
        entity: SEARCH_TABLES.DELIVERIES,
        orgID: globalOrgId || organization.id,
        body: searchBody,
        success: (res) => {
          dispatch(
            setSearchState({
              currentData: res.data.data ?? [],
              currentPaginationData: res.data.pagination
            })
          );

          if (!res.data.data) {
            dispatch(setUserSearch({ page: 1 }));
          }
        }
      })
    );
  };

  const onCreateOrEditSuccess = () => {
    getDeliveries();
    closeModal();
    dispatch(
      setToast({
        toastShow: true,
        toastMessage: delivery ? t('Delivery has been successfully updated!') : t('Delivery has been successfully created!')
      })
    );
  };

  const onCreateOrEditFail = () => {
    dispatch(
      setToast({
        toastShow: true,
        toastMessage: delivery ? t('Delivery update failed!') : t('Delivery creation failed!'),
        modal: 'errorToast'
      })
    );
  };

  const createOrUpdateDelivery = (values, deliveryImageUrl = "") => {
    const deliveryData = {
      org_id: globalOrgId || organization.id,
      carrier_id: values.carrier_id,
      user_id: values.user.value,
      building_id: values.building_id,
      image: deliveryImageUrl || delivery?.image || "",
      weight: values.weight,
      dimensions: values.dimensions,
    };

    if (delivery) {
      dispatch(
        updateDelivery({
          orgId: globalOrgId || organization.id,
          deliveryId: delivery.id,
          body: deliveryData,
          success: onCreateOrEditSuccess,
          fail: onCreateOrEditFail
        })
      );
    } else {
      dispatch(
        createDelivery({
          orgId: globalOrgId || organization.id,
          body: deliveryData,
          success: onCreateOrEditSuccess,
          fail: onCreateOrEditFail
        })
      );
    }
  }

  const handleSubmit = (values) => {
    if (values.delivery_image) {
      const imageData = new FormData();
      imageData.append('image', values.delivery_image.blob, values.delivery_image.fileName);

      dispatch(uploadDeliveryImage({
        body: imageData,
        success: (res) => {
          // filename is the image URL here
          createOrUpdateDelivery(values, res.data.filename);
        },
        fails: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Failed to upload delivery image!'),
              modal: 'errorToast'
            })
          );
        }
      }));
    } else {
      createOrUpdateDelivery(values);
    }
  }

  const createLabelForResidentSelector = (user) => {
    return `${user.first_name} ${user.last_name}${user.unit_num ? `, Unit ${user.unit_num}` : ''}`;
  }

  // Get carrier companies
  useEffect(() => {
    const searchBody = { ...searchOptions };
    dispatch(
      searchTable({
        entity: SEARCH_TABLES.CARRIER_COMPANIES,
        orgID: globalOrgId || organization.id,
        body: searchBody,
        success: (res) => {
          dispatch(setCarriers({
              data: res.data.data ?? [],
              pagination: res.data.pagination    
          }));
        }
      })
    );
  }, [globalOrgId, organization.id]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Flex direction="column" gap="24px">
          {stepIndex === 0 ? <UploadDeliveryImage form={form} onClickNext={goToNextStep} /> : (
            <>
              <FormField
                control={form.control}
                name="building_id"
                render={({ field }) => (
                  <FormItem alt>
                    <FormLabel> {t('Building')}</FormLabel>
                    <FormControl>
                      <Select
                        {...field}
                        placeholder={'Please select'}
                        onChange={(e) => {
                          if (delivery) {
                            form.resetField('user', { defaultValue: null });
                          } else {
                            form.resetField('user');
                          }
                          field.onChange(e);
                        }}>
                        {allBuildings?.length > 0 ? (
                          <SelectItem value={-1}>{t('Please select')}</SelectItem>
                        ) : (
                          <SelectItem value={-1}>{t('No Buildings Available')}</SelectItem>
                        )}

                        {allBuildings.map((building) => (
                          <SelectItem key={building.id} value={building.id}>
                            {building.name}
                          </SelectItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="user"
                render={({ field }) => (
                  <FormItem alt>
                    <FormLabel>{t('Resident')}</FormLabel>
                    <FormControl>
                      <UserAsyncDropDown
                        {...field}
                        skip_deleted={true}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        type={1}
                        buildingId={buildingId}
                        only_residents
                        createCustomLabel={createLabelForResidentSelector}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="carrier_id"
                render={({ field }) => (
                  <FormItem alt>
                    <FormLabel> {t('Carrier')}</FormLabel>
                    <FormControl>
                      <Select
                        {...field}
                        placeholder={'Please select'}
                        onChange={(e) => {
                          field.onChange(e);
                        }}>
                        {carriersList.length > 0 ? (
                          <SelectItem value={-1}>{t('Please select')}</SelectItem>
                        ) : (
                          <SelectItem value={-1}>{t('No Carriers Available')}</SelectItem>
                        )}

                        {carriersList.map((carrier) => (
                          <SelectItem key={carrier.id} value={carrier.id}>
                            {carrier.name}
                          </SelectItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="weight"
                render={({ field }) => (
                  <FormItem alt>
                    <FormLabel>{t('Weight')}</FormLabel>
                    <FormControl row>
                      <SettingsModalInput
                        type="text"
                        placeholder="50lb"
                        autoComplete="off"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="dimensions"
                render={({ field }) => (
                  <FormItem alt>
                    <FormLabel>{t('Dimensions')}</FormLabel>
                    <FormControl row>
                      <SettingsModalInput
                        type="text"
                        placeholder="20cm x 20cm x 40cm"
                        autoComplete="off"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}

          <ButtonsContainer gapY="29px">
            <ButtonBodyVariant type="button" variant="outline" onClick={closeModal}>{t('Close')}</ButtonBodyVariant>
            {stepIndex === 0
             ? <ButtonBodyVariant 
                  type="button" 
                  onClick={goToNextStep} 
                  disabled={form.formState.errors.hasOwnProperty("delivery_image")}
                >
                  {t('Next')}
                </ButtonBodyVariant> 
             : <ButtonBodyVariant type="submit" disabled={form.formState.isSubmitting}>{t('Confirm')}</ButtonBodyVariant>
            }
          </ButtonsContainer>
        </Flex>
      </form>
    </Form>
  );
}

export default AddEditDeliveryForm;

const ButtonsContainer = styled(Flex)`
  padding-bottom: 1px;
  
  & > * {
    flex-grow: 1
  }
`;
