export const ASC = 'ASC';
export const DESC = 'DESC';

export const HTTP_STATUS = {
  forbidden: 403
};

export const SEARCH_TABLES = {
  ORGANIZATIONS: 'organizations',
  USERS: 'users',
  BOOKINGS: 'bookings',
  BUILDINGS: 'buildings',
  DELIVERY_CODES: 'delivery_codes',
  WORK_REQUESTS: 'service_requests',
  OFFERS: 'offers',
  DELIVERIES: 'deliveries',
  CARRIER_COMPANIES: 'carrier_companies'
}

export const DEFAULT_PAGINATION_COUNT = 20;

export const NEW_NOTIFICATION_KEY = 'newNotification';
export const USER_ID_KEY = 'user_id';
export const whiteListedDomains = ['@grata.life'];

export const TIME_ZONES = new Map([
  ["AL", "America/Chicago"],
  ["AK", "America/Anchorage"],
  ["AS", "Pacific/Pago_Pago"],
  ["AZ", "America/Phoenix"],
  ["AR", "America/Chicago"],
  ["CA", "America/Los_Angeles"],
  ["CO", "America/Denver"],
  ["CT", "America/New_York"],
  ["DE", "America/New_York"],
  ["DC", "America/New_York"],
  ["FM", "Pacific/Ponape"],
  ["FL", "America/New_York"],
  ["GA", "America/New_York"],
  ["GU", "Pacific/Guam"],
  ["HI", "Pacific/Honolulu"],
  ["ID", "America/Boise"],
  ["IL", "America/Chicago"],
  ["IN", "America/Indiana/Indianapolis"],
  ["IA", "America/Chicago"],
  ["KS", "America/Chicago"],
  ["KY", "America/New_York"],
  ["LA", "America/Chicago"],
  ["ME", "America/New_York"],
  ["MH", "Pacific/Majuro"],
  ["MD", "America/New_York"],
  ["MA", "America/New_York"],
  ["MI", "America/New_York"],
  ["MN", "America/Chicago"],
  ["MS", "America/Chicago"],
  ["MO", "America/Chicago"],
  ["MT", "America/Denver"],
  ["NE", "America/Chicago"],
  ["NV", "America/Los_Angeles"],
  ["NH", "America/New_York"],
  ["NJ", "America/New_York"],
  ["NM", "America/Denver"],
  ["NY", "America/New_York"],
  ["NC", "America/New_York"],
  ["ND", "America/Chicago"],
  ["MP", "Pacific/Saipan"],
  ["OH", "America/New_York"],
  ["OK", "America/Chicago"],
  ["OR", "America/Los_Angeles"],
  ["PW", "Pacific/Palau"],
  ["PA", "America/New_York"],
  ["PR", "America/Puerto_Rico"],
  ["RI", "America/New_York"],
  ["SC", "America/New_York"],
  ["SD", "America/Chicago"],
  ["TN", "America/Chicago"],
  ["TX", "America/Chicago"],
  ["UT", "America/Denver"],
  ["VT", "America/New_York"],
  ["VI", "America/St_Thomas"],
  ["VA", "America/New_York"],
  ["WA", "America/Los_Angeles"],
  ["WV", "America/New_York"],
  ["WI", "America/Chicago"],
  ["WY", "America/Denver"],
  ["AB", "America/Edmonton"],
  ["BC", "America/Vancouver"],
  ["MB", "America/Winnipeg"],
  ["NB", "America/Moncton"],
  ["NL", "America/St_Johns"],
  ["NT", "America/Yellowknife"],
  ["NS", "America/Halifax"],
  ["NU", "America/Rankin_Inlet"],
  ["ON", "America/Toronto"],
  ["PE", "America/Halifax"],
  ["QC", "America/Toronto"],
  ["SK", "America/Regina"],
  ["YT", "America/Whitehorse"]
]);
