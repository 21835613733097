import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { TheAside, TheContent, TheFooter, TheHeader, TheSidebar } from './containers/index';
import {
  getAllBuildings,
  getBuildings,
  setGlobalOrgId,
  setCachedBuildings,
  setLoadingCachedBuildings
} from './redux/actions/building';
import {
  getOrganizations,
  getAllOrganizations,
  getOrganization
} from './redux/actions/organization';
import {
  getAmenityTypes,
  getCategories,
  getServiceRequestCategories
} from './redux/actions/services';
import { searchUsers } from './redux/actions/user';

import { setConfig, setIsLoggedIn, setRoleId } from './redux/actions/auth';
import { getAllMessages } from './redux/actions/conversationSent';
import { getProfile } from './redux/actions/profile';
import { setMode } from './redux/actions/window';
import './scss/style.scss';

import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';
import LoadingAvatar from './components/LoadingAvatar';
import { getNotifications, getPagedNotif, showMessage } from './redux/actions/notifications';
import { getCountry } from './redux/actions/country';
import { UserRole } from './views/users/constant';
import { LOADING_BLUE } from './utils/colorCode';
import LostInternetPage from './components/LostInternetPage';
import ServiceDetail from './views/services/ServiceDetail';
import ServiceDetailEdit from './views/services/ServiceDetailEdit';
import { setGlobalOrganization } from './helper/store';

const Login = React.lazy(() => import('./views/pages/login'));
const ResetPassword = React.lazy(() => import('./views/pages/login/SetPassword'));
const CheckRoute = React.lazy(() => import('./components/CheckRoute'));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  const [isOnline, setIsOnline] = useState(true);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { darkMode } = useSelector((state) => state.window);
  const { globalBuildingId } = useSelector((state) => state.building);

  const { globalOrgId } = useSelector((state) => state.Organization);

  const [orgId, setOrgId] = useState(localStorage.getItem('orgId'));

  const { user, searchFields } = useSelector((state) => state.user);
  const { modal } = useSelector((state) => state.headerModal);

  const dispatch = useDispatch();

  const classes = classNames('c-app c-default-layout', darkMode && 'c-dark-theme');

  useEffect(() => {
    handleConnectionChange();
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, []);

  const handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    // it is possible that navigator is online, but still no internet access
    if (condition === 'online') {
      const webPing = setInterval(() => {
        fetch('//google.com', {
          mode: 'no-cors'
        })
          .then(() => {
            setIsOnline(true);
            clearInterval(webPing);
          })
          .catch(() => setIsOnline(false));
      }, 2000);
      return;
    }

    return setIsOnline(false);
  };
  useEffect(() => {
    const fcmChannel = new window.BroadcastChannel('fcm-channel');
    fcmChannel.onmessage = (message) => {
      dispatch(
        showMessage({
          notification: {
            title: 'NEW'
          }
        })
      );
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isOnline) return;
    const building_id = localStorage.getItem('building_id');
    const user_id = localStorage.getItem('user_id');
    const error_code = localStorage.getItem('error_code');
    const config = localStorage.getItem('config');
    const dataCenter = localStorage.getItem('data_center') || '';
    const idToken = localStorage.getItem('idToken') || '';
    const refreshToken = localStorage.getItem('refreshToken') || '';
    const roleId = parseInt(localStorage.getItem('roleId')) || '';
    const mode = localStorage.getItem('darkMode') || '';
    setOrgId(localStorage.getItem('orgId'));
    if (mode === 'true') {
      dispatch(setMode({ darkMode: true }));
    } else {
      dispatch(setMode({ darkMode: false }));
    }

    if (idToken && refreshToken && parseInt(error_code) === 0) {
      dispatch(setIsLoggedIn({ isLoggedIn: true }));
      dispatch(
        setRoleId({
          roleId: roleId,
          building_id: building_id,
          orgId: orgId,
          user_id: user_id
        })
      );
      if (roleId === UserRole.SUPER_ADMIN) {
        dispatch(
          getOrganizations({
            location: dataCenter
          })
        );

        dispatch(
          getAllOrganizations({
            location: dataCenter
          })
        );

        if (orgId) {
          dispatch(
            getBuildings({
              id: globalOrgId !== -1 ? globalOrgId : orgId,
              page: 1
            })
          );
        }
      }
      if (globalOrgId !== 0) {
        dispatch(getServiceRequestCategories({ orgId: globalOrgId }));
        dispatch(
          getOrganization({
            id: globalOrgId,
            success: (org) => {
              setGlobalOrganization(org.data);
            }
          })
        );
      }

      const hasGlobalOrg = () => {
        return (roleId === UserRole.SUPER_ADMIN || roleId === UserRole.ADMIN) && globalOrgId > 0;
      };
      const curOrg = hasGlobalOrg() ? globalOrgId : orgId;
      if (curOrg > 0) {
        dispatch(
          getAllBuildings({
            id: curOrg
          })
        );
      }

      dispatch(
        getAllMessages({
          id: parseInt(globalOrgId != -1 ? globalOrgId : orgId)
        })
      );

      dispatch(getProfile());
      dispatch(getNotifications());
      dispatch(setConfig(config));
      dispatch(setConfig(config));

      dispatch(getCountry(dataCenter));

      dispatch(getAmenityTypes());

      if (roleId === UserRole.SUPER_ADMIN) {
        dispatch(setLoadingCachedBuildings({ orgId: 0, isLoading: true }));
        dispatch(
          setCachedBuildings({
            id: 0,
            success: () => {
              dispatch(setLoadingCachedBuildings({ orgId: 0, isLoading: false }));
            }
          })
        );
      }
    } else {
      dispatch(setIsLoggedIn({ isLoggedIn: false }));
    }
  }, [dispatch, globalOrgId, globalBuildingId, user, isOnline]);

  useEffect(() => {
    if (!isOnline) return;
    const idToken = localStorage.getItem('idToken') || '';
    const refreshToken = localStorage.getItem('refreshToken') || '';
    const error_code = localStorage.getItem('error_code');
    const roleId = parseInt(localStorage.getItem('roleId'), 10) || -1;
    if (idToken && refreshToken && parseInt(error_code) === 0) {
      if (globalBuildingId >= 0 || roleId === UserRole.SUPER_ADMIN) {
        dispatch(
          searchUsers({
            ...searchFields,
            building: globalBuildingId,
            org: globalOrgId != -1 ? globalOrgId : orgId,
            page: 1
          })
        );
      }
    }
  }, [dispatch, globalOrgId, globalBuildingId, isOnline]);

  useEffect(() => {
    if (!isOnline) return;
    const roleId = parseInt(localStorage.getItem('roleId'), 10) || -1;
    // if (roleId != UserRole.SUPER_ADMIN) {
    if (orgId) {
      dispatch(setGlobalOrgId(orgId));
    }
    // }
  }, [orgId, isOnline]);

  if (!isOnline) {
    return <LostInternetPage />;
  }
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          {isLoggedIn === false ? (
            <>
              <Route exact path="/login" name="Login" render={(props) => <Login {...props} />} />
              <Route path="/set-password" component={ResetPassword} />
              <Route component={CheckRoute} />
            </>
          ) : isLoggedIn === null || isLoggedIn === undefined ? (
            <LoadingAvatar color={LOADING_BLUE} />
          ) : (
            <>
              <div className={classes}>
                <Route exact path="/" render={() => <Redirect to={'/dashboard'} />} />
                <Route exact path="/login" render={() => <Redirect to={'/dashboard'} />} />
                <TheSidebar />
                <TheAside />
                <div className="c-wrapper">
                  <TheHeader />
                  <div className="c-body">
                    <TheContent />
                  </div>
                  {/* {modal === 'serviceDetail' && <ServiceDetail />}
                  {modal === 'serviceDetailEdit' && <ServiceDetailEdit />} */}
                </div>
              </div>
            </>
          )}
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
