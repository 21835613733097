import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import routes from '../routes/index';
import { UserRole } from '../views/users/constant';

function useRoutes(initialValue = false) {
  const Deliveries = React.lazy(() => import('../views/Deliveries/Deliveries.jsx'));

  const { organization } = useSelector((state) => state.Organization);

  const [routesList, setRouteList] = useState([]);

  const { roleId } = useSelector((state) => state.auth);

  useEffect(() => {
    if (organization.config != undefined) {
      //Add protected routes here
      const protectedRoutes = [
        {
          path: '/deliveries',
          name: 'Deliveries',
          component: Deliveries,
          exact: true,
          viewable: roleId == UserRole.SUPER_ADMIN ? true : organization.config.enable_deliveries //use this boolean attribute to validate if the route is viewable
        }
      ];

      let newRouteList = protectedRoutes.concat(routes);
      setRouteList(newRouteList);
    }
  }, [organization]);

  return [routesList];
}

export default useRoutes;
